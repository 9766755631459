.subscription
  width: 100%
  display: flex
  align-items: center
  flex-direction: column
  padding-bottom: 32px

.starWrapper
  padding-bottom: 10px
  color: #818894

.subBlock
  padding-left: 16px
  padding-right: 16px

.subscriptionOne
  display: flex
  justify-content: center
  text-align: center
  padding-bottom: 20px

.subscript
  display: flex
  justify-content: center

  button
    align-self: flex-start

.subscriptionInpput
  width: 296px
  padding-right: 16px

.subscriptionButton
  display: flex
  justify-content: center

.agreement
  display: flex
  justify-content: center
  text-align: center
  padding-top: 28px

// .safety
//   padding-left: 400px
//   padding-bottom: 66px

.safetyGG
  display: flex
  justify-content: center

.wrapper
  width: 100%
  display: grid
  background-color: #fff
  padding-top: 60px

  &BlackFriday
    background-color: #000000 !important

.text
  color: #031228

  &Bf
    color: #ffff !important


.innerWrapper
  display: grid
  width: 100%
  grid-template-columns: 1fr 1fr 1fr
  border-top: 1px solid var(--border-color)
  border-bottom: 1px solid var(--border-color)

.footerUL
  list-style: none
  padding-left: 0

.footerLI
  font-size: 14px
  line-height: 20px
  display: flex
  padding-top: 1px

.secondFooter
  display: grid
  grid-template-columns: 1fr 1fr 1fr
  grid-gap: 70px
  width: 100%
  text-align: center
  margin-top: 30px

@media (max-width: 992px)
  .secondFooter
    grid-template-columns: repeat(2, 1fr)

@media (max-width: 768px)
  .secondFooter
    gap: 24px
    grid-template-columns: 1fr

.line
  width: 100%
  border: 1px solid var(--border-color)

.number
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column

.info
  width: 100%
  padding-top: 8px

.footerSocial
  display: flex
  flex-direction: column
  justify-content: center
  gap: 4px

@media(max-width: 768px)
.footerSocial
  gap: 10px

.social
  display: flex
  align-items: center
  justify-content: space-evenly
  gap: 10px

  @media (max-width: 992px)
    .social
      justify-content: space-between

.footerCompany
  display: flex
  justify-content: center
  align-items: center
  text-align: center
  padding: 38px 15px 15px 28px

.inst
  display: flex
  text-decoration: none

.blogCompany
  min-width: 59px
  padding: 6px 12px !important

.descriptionStyle
  font-size: 12px
  color: #818894

.subscriptionGap
  padding-bottom: 0px

.footerSea
  display: flex
  width: 100%
  align-items: center
  justify-content: center
  padding-top: 16px
  padding-bottom: 16px
  background-color: var(--shadow-primary-color)

@media ( max-width: 768px  )
.subscriptionOne
  padding-right: 0
  width: 100%
  padding-bottom: 20px

.agreement
  padding-right: 0
  width: 100%
  padding-top: 28px
  padding-bottom: 32px

.subscriptionForm
  display: grid
  grid-template-columns: 1fr 1fr 1fr
  grid-gap: 16px
  width: 100%

.subscriptionInpput
  padding-right: 0
  width: 100%
  padding-bottom: 16px

.safety
  padding-left: 60px

.wrapper
  width: 100%
  background-color: #fff

// .innerWrapper
//   display: grid

//   @media (max-width: 768px)
//     .innerWrapper
//       grid-template-columns: 1fr
//       grid-template-rows: 1fr 1fr 1fr

.footerUL
  list-style-type: none
  padding-left: 0
@media (max-width: 992px)
  .subscriptionForm
    button
      min-width: 30%

@media (max-width: 768px)
  .subscriptionForm
    grid-template-columns: unset

.paymentMethods
  display: grid
  justify-content: center
  align-items: center
  grid-template-columns: 1fr 1fr
  gap: 35px

@media (max-width: 992px)
  .paymentMethods
    grid-column: 1 / -1
    grid-gap: 48px

@media (max-width: 992px)
  .paymentMethodTinkoff
    display: flex
    justify-content: end
    align-items: center

@media (max-width: 992px)
  .paymentMethodSbp
    display: flex
    justify-content: start
    align-items: center

@media (max-width: 768px)
  .innerWrapper
    grid-template-columns: unset
